export const cmdUrl = {
  File: {
    root: '/file',
    download: '/file/download',
    upload: '/file/upload',
  },
  Bill:{
    upload: '/bills/upload-files',
    uploadInvoice: '/bills/upload-files-invoices',
    addRefundBill: 'bills/refund-bill',
  },
  TransactionUrl: {
    root: '/transactions',
    byId: `/transactions/{id}`,
    forBill: '/transactions/bill/{number}',
    forStock: '/transactions/stock/{code}',
    forOrder: '/transactions/order/{number}'
  },
  TransactionCategory: {
    root: '/transactionCategory'
  },
  AccountantUrl: {
    byType: '/accountants/getByType',
    getAll: '/accountants/getAll',
    imports: '/accountants/imports'
  },
  CustomerUrl: {
    root: '/customer'
  },
  EmployeeUrl: {
    root: '/employees'
  },
  ProviderUrl: {
    root: '/providers'
  },
  InstallmentsUrl: {
    root: '/installments'
  },
  DebtUrl: {
    installments: '/debts/installment-transactions',
    credits: '/debts/credit-transactions',
    providers: '/debts/provider-transactions',
    debtInstallments: '/debts/installments',
    debtProviders: '/debts/providers',
    creditOrders: '/debts/credit-order-transactions',
    installmentOrders: '/debts/installment-order-transactions',
    shipFeeOrders: '/debts/ship-fee-order-transactions',
  },
  BillUrl: {
    byCustomer: '/bills/customer/{id}'
  },
  PromotionUrl: {
    root: 'promotions',
    byId: 'promotions/{id}'
  },
  WarrantyPackageUrl: {
    root: 'warrantyPackages',
    byId: 'warrantyPackages/{id}'
  },
  AuthUrl: {
    changePwd: '/change-password'
  },
  Reports: {
    accountingByStore: '/report/accountings/stores',
    saleSummariesByStore: '/report/accountings/sale-summaries'
  },
  DiscountProgramUrl: {
    root: 'discount-programs',
    byId: 'discount-programs/{id}',
    byProduct: '/discount-programs/by-product'
  },
  OrderUrl: {
    gets: '/orders/'
  },
  TradeInUrl: {
    byCustomer: '/tradeIn/by-customer'
  },
  InstallmentProgram:{
    root: 'installment-programs',
    byId: 'installment-programs/{id}',
  },
  Campaign: {
    root: 'campaigns',
    byId: 'campaigns/{id}',
  },
  POSMForm: {
    root: 'posm-forms',
    byUuid: 'posm-forms/{uuid}',
  },
  PosmFormSubmission: {
    root: 'posm-submissions',
    byUuid: 'posm-submissions/{uuid}',
  },
  // ToolSync: {
  //   bill: 'http://localhost:25295/bill-nhanhs/',
  //   billId: 'http://localhost:25295/bill-nhanhs/{id}',
  //   billSearch: 'http://localhost:25295/bill-nhanhs/search',
  //   product: 'http://localhost:25295/product-nhanhs/',
  //   productSearch: 'http://localhost:25295/product-nhanhs/search',
  //   productExcel: 'http://localhost:25295/product-nhanhs/excel',
  //   cronGets: 'http://localhost:25295/cron-jobs',
  //   functionGets: 'http://localhost:25295/functions',
  //   cronStart: 'http://localhost:25295/cron-jobs/start',
  //   cronStop: 'http://localhost:25295/cron-jobs/stop',
  //   cronRemove: 'http://localhost:25295/cron-jobs/destroy',
  //   order: 'http://localhost:25295/order-nhanhs/',
  //   imei: 'http://localhost:25295/imei-nhanhs/',
  //   imeiSearch: 'http://localhost:25295/imei-nhanhs/search',
  //   imeiExcel: 'http://localhost:25295/imei-nhanhs/excel'
  // }
  ToolSync: {
    bill: 'https://app.didongviet.vn/tool-sync/bill-nhanhs/',
    billId: 'https://app.didongviet.vn/tool-sync/bill-nhanhs/{id}',
    billSearch: 'https://app.didongviet.vn/tool-sync/bill-nhanhs/search',
    product: 'https://app.didongviet.vn/tool-sync/product-nhanhs/',
    productSearch: 'https://app.didongviet.vn/tool-sync/product-nhanhs/search',
    productExcel: 'https://app.didongviet.vn/tool-sync/product-nhanhs/excel',
    cronGets: 'https://app.didongviet.vn/tool-sync/cron-jobs',
    functionGets: 'https://app.didongviet.vn/tool-sync/functions',
    cronStart: 'https://app.didongviet.vn/tool-sync/cron-jobs/start',
    cronStop: 'https://app.didongviet.vn/tool-sync/cron-jobs/stop',
    cronRemove: 'https://app.didongviet.vn/tool-sync/cron-jobs/destroy',
    order: 'https://app.didongviet.vn/tool-sync/order-nhanhs/',
    imei: 'https://app.didongviet.vn/tool-sync/product-nhanhs/',
    imeiSearch: 'https://app.didongviet.vn/tool-sync/imei-nhanhs/search',
    imeiExcel: 'https://app.didongviet.vn/tool-sync/imei-nhanhs/excel'
  }
};
